import styles from "./Timer.module.css";

export function Timer({ timeStr }: { timeStr: string }) {
   return (
      <div className={`box ${styles.wrap}`}>
         <div className={styles.content}>{timeStr}</div>
         {/* <div>Until Official Launch</div> */}
      </div>
   );
}
